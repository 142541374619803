import React from 'react'
import './navbar.css'

const Navbar = () => {
  return (
    <nav className="navbar">
        <div className="nav-left">
            <img src="images/logo.png"/>
        </div>
        <div className="nav-right">
            <ul className="nav-links">
                <li className="nav-link"><a href="#">ABOUT US</a></li>
                <li className="nav-link"><a href="#">PROJECTS</a></li>
                <li className="nav-link"><a href="#">DIVERSIFICATION</a></li>
                <li className="nav-link"><a href="#">OUR PROCESS</a></li>
                <li className="nav-link"><a href="#">TESTIMONIALS</a></li>
                <li className="nav-link"><a href="#">CAREERS</a></li>
                <li className="nav-link"><a href="#">CONTACT US</a></li>
            </ul>
        </div>
    </nav>
  )
}

export default Navbar