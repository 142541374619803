import React from 'react'
import './about.css'

const About = () => {
  return (
    <>
      <section className="home">
        <h2 className="heading">ABOUT US</h2>
        <div className="home-top">
          <div className="logo">
            <img src="images/logo2.png" />
          </div>

          <div className="bg">
            <p style={{ width: "100vw" }}></p>
          </div>
        </div>

        <div className="home-cards">
          <p>We endeavour to build homes which make it's residents happy and proud of their address. Residents are at
            heart of everything we do, we build homes to live in, not houses to stay in.
          </p>
          <div className="logo logo2">
            <img src="images/logo2.png" />
          </div>

          <div className="card-container">
            <div className="card">
              <img src="images/cardimg1.png" />
              <p className="card-text">Our track record has been impeccable with <strong>project completion on
                time</strong> and have surpassed expectations many a time, society members have positively
                received our redevelopment plans and encourage our <strong>modern and urbane designs</strong> to
                their neighbours.</p>
            </div>

            <div className="card">
              <img src="images/cardimg2.png" style={{ bottom: "15px" }} />
              <p className="card-text">The Hetali Group is a redeveloper like no other with a <strong> profound focus
                on quality </strong> and <strong>long term satisfaction of its residents.</strong> </p>
            </div>

            <div className="card">
              <img src="images/cardimg3.png" />
              <p className="card-text">They just don't endeavour but build homes which make it's residents proud of
                their address <strong> by putting home-owners at the heart and soul of everything </strong>they
                do from start to finish and post completion. </p>
            </div>

            <div className="card">
              <img src="images/cardimg4.png" style={{ bottom: "8px" }} />
              <p className="card-text">Hetali Group lives by the ethos of <strong>building homes to live and not
                houses to stay in. </strong>. </p>
            </div>

          </div>

          <div className="bg" style={{ background: "#000000", opacity: "0.8" }}>
            <p style={{ width: "100vw" }}></p>
          </div>

          <div className="paragraph">
            <p>Established in 1992, Hetali Group is a leading real estate developer of residential spaces across
              Mumbai and have earned an unmatched reputation amongst its very loyal customers. The company's
              customer-centric values, project management proficiency and technical expertise are amplified with
              over 6.5 lakh sq.ft. built and almost 1 lakh sq.ft underway leading to more than families!
            </p>

            <p> The Hetali Group promises a lifestyle second to none. In a span of over four decades, the group has
              proved to be one of the most reputed real estate builders in the western suburbs of Mumbai with
              residences in prime locations such as Andheri, Goregaon, Vile Parle and Juhu.</p>

            <p>Spearheaded by Mr Jayesh Pandya, the company's central ideology is to provide homes customized to
              the customer's requirements and home’s that are superior in nature keeping in mind the needs of the
              modern home user.</p>
          </div>
        </div>
      </section>

      <section className="vision">
        <div className="vision-box">
          <h2 className="heading">VISION</h2>
          <p>We aim to ensure top-class service to our stakeholders & diversify our business to other service-oriented
            sectors with the ultimate goal of achieving customer satisfaction & end-user interaction.</p>
        </div>
      </section>


      <section className="mission">
        <h2>MISSION</h2>
        <div className="logo" style={{ marginTop: "20px" }}>
          <img src="images/logo2.png" />
        </div>

        <div className="mission-box">
          <div className="mission-box-left">
            <img
              src="https://s3-alpha-sig.figma.com/img/e425/0553/d20c2e9d45bd59bf598df15ac0583eab?Expires=1655078400&Signature=ZvppB3s9kgXm5b-5PRR0JZVCrPYf~fUT8N3KqzsZqfJnJJMQkm~eSbtGQ2AwNTuIXHhoZT4WdLJqgogPxszCzSkJSvTlmwlzfdDiA3s8x3UL97B8s31vvdUxnSDIhnt8oW5jUTNtkIjiAr6eqVMkGJDlncvIOica6g0Ll716f1ATryv0ZVgMEwJrcSh88bYz6Wr0efKtEIx9vo06IVb-mzVTHwmy7w7iFs5NQ09SXcCF9dwzLOhyJ7l4XcVrRlrVzjnPuLZ-9RYsq8EW5tob6J-SmDpdHv7RIbQcz6FLQPaRFjH4emQHsG-y6EBwcw8s0eTNtzolszp20UxcwWiZbw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" />
          </div>
          <div className="mission-box-right">
            <div className="mission-box-cards">
              <div>
                <details open>
                  <summary>
                    <p>Personalised</p>
                  </summary>
                  <div>
                    Our core idealogy is to provide personalised homes to meet the very specific needs of
                    residents.
                  </div>
                </details>

                <details>
                  <summary>
                    <p> Committed</p>
                  </summary>
                  <div>

                  </div>
                </details>

                <details>
                  <summary>
                    <p>Quality & Service Oriented</p>
                  </summary>
                  <div>

                  </div>
                </details>

                <details>
                  <summary>
                    <p>Timely </p>
                  </summary>
                  <div>

                  </div>
                </details>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className="mission social-res">
        <h2>CORPORATE SOCIAL RESPONSIBILITIES</h2>
        <div className="logo" style={{ marginTop: "20px" }}>
          <img src="images/logo2.png" />
        </div>

        <div className="mission-box">

          <div className="mission-box-right social-res-box">
            <div className="mission-box-cards">
              <div>
                <details open>
                  <summary>
                    <p>Education for the under-privileged</p>
                  </summary>
                  <div>
                    We believe that our future lies in the holistic educational foundation of the youth and
                    we have nurtured that cause by providing the necessary books and monetary support to the
                    under-privileged children of Mumbai & also at several villages in Gujarat. As the
                    Trustees of the H A Desai Boarding School in Matunga, Mumbai, we have been providing
                    living and hospitality services to the academically bright students from the weaker
                    monetary sections of the society.

                  </div>
                </details>

                <details>
                  <summary>
                    <p> Support for Rural Farmers</p>
                  </summary>
                  <div>

                  </div>
                </details>

                <details>
                  <summary>
                    <p>Spiritual</p>
                  </summary>
                  <div>

                  </div>
                </details>

              </div>
            </div>
          </div>

          <div className="mission-box-left social-res-box">
            <img
              src="https://s3-alpha-sig.figma.com/img/6c70/83b2/ff3362d34c9ee67964954667808aa563?Expires=1655078400&Signature=H9mDDeQ~EAZsAeR8nTRL8sAZlpWyvp9dYdFlt-cP0FDTpYVJarl6cgSL0MM-PkQXU4o0bR9WZhQk-Uf0insUgmmew0fmYujLBirGtDXMekGWPGiXyWY1Kzl~wSHNCfM5-x1SNxGFS2vBqFaoxd9ih~8zW-85TrLIZYe9a~ir9-BnurVTUtxKThuO9rsJyklcz~moYQUHOt5u3sm-CYVRjigz7bgvNguiMw1VxViydyrSEe16i7rzwgtnQBd6lJPjwyzK9j574hTcCZfXKQg1ER~Ow4fQiWWQXTxZqqGX99XP0NH~eYtRzmoxvquyzRWsHJDXF5t2K9hc5X1XqjDlzg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" />
          </div>

        </div>
      </section>

      <section className="founder-message">
        <div className="founder">
          <h2 className="heading">FOUNDER’S MESSAGE</h2>
          <div className="img">
            <img src="images/founder.png" />
          </div>
          <div className="logo logo3" style={{ marginTop: "0" }}>
            <img src="images/logo2.png" />
          </div>
          <h3 className="heading">Mr. Jayesh H. Pandya </h3>
          <h4>“When a customer buys a home, he is giving up a major chunk of his life's savings to do so. It is
            imperative to give him exactly what he looks for in his home.”
          </h4>

          <p>
            We bring to our clients much wanted human values, integrity, trust and an assurance of responsibility
            all coupled with our expertise in construction, engineering & management. Our biggest assets have always
            been our small but highly trained team of professional managers and engineers who are backed by a very
            able team of administrators. We have a clear vision about building on our strengths and our achievements
            while holding dear to us our basic principles

          </p>
        </div>

        <div className="logo logo2">
          <img src="images/logo2.png" />
        </div>

      </section>

      <section className="group-companies">
        <h2 className="heading">GROUP COMPANIES</h2>
        <div className="logo">
          <img src="images/logo2.png" />
        </div>

        <div className="group-companies-box">
          <div className="img-box img-box-1 ">
            <img
              src="https://s3-alpha-sig.figma.com/img/a784/7475/a363a5e5a865dddf20863510a02616d7?Expires=1655078400&Signature=b1KTpdbffTzdEI1RmLT6V1uuZDq-43hrfdUaaFBOsMvkjvXpVaDvv18wMzvq1rAHg~ZeArqOaHwvrOtawrSw448U5hBxc6~OTQuzRZ9WaJG9ZQwCmo4OQ2zC6ZxHHeg~03RxPHTGlqgJVteEd-pUDG11fmPwNOT66wyfzlxBLzQjQCBw-64PHNUl8dYlcOC1z0AXzXHDE25ozF7WLKvXID6G7rCoswLWlSGXoaYio0PepmOCVgnnLmYK3yGxiiqLQVbmo~fezDPCPEVM5d48ZhUyJFV0tx3qY5AuLn~nTZDa2d08P~vlPpdpmfxt6F09Dizw~KwbcGAmrLALidoaUw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" />
            <p>Agriculture</p>
          </div>
          <div className="img-box img-box-2">
            <img
              src="https://s3-alpha-sig.figma.com/img/4458/c9ad/b231b7d3d6f57873e1a8401bee86bd2a?Expires=1655078400&Signature=a1soYB1yNm0ydT6iE2WKUKOU6mN-0xt9Rr5CSbL1pCsaHA3gaw~f0qtAb3yZxkeMYkarrOSyTGNXZijc6~UCOR39nLCKQPmaz~h7bOD4lnapwFIDWy-T3utgHTSuXfhctiOL~2G~7HHxMCZuEKAKeylh3ueHlmXRZoMNy8FLkByG5l7Al17aI4P1G0nDWGj-~Z0nr7wRSV1rKy6WlXHBlYOJ7r7MEutYKVuIZdr46xKQMbwBXuvUOBjlmHnMtgUxfONNAEuHgpB08hWJ1sO6Ad9hE6VwLSQ69tyzQ-B0Y19EMAhecNdBSlVAca9~H7UBYC9RMOe4v~f7nMCMSuGcDQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" />
            <p>Fuel</p>
          </div>
          <div className="img-box img-box-3">
            <img
              src="https://s3-alpha-sig.figma.com/img/8e1d/34af/1e8ed92b5c033e66d27e5af51d3871a2?Expires=1655078400&Signature=Gr3CQaR6u7Yb3hgL7eJpIwjITQs7wDU4JZxMwvZLP6gHMU5mejL~PXGT30sliymZwC2yrgsTJjDcNN~WR~jdY~pfdRiHGuTtYcfYvLrE12tA4sdQ98OPqWRQqCKv8tX5cXYXcIZmkouV5iWPeUvQEdAbd49U9xLMH-Z3rkpBBSrFgbVwNSaQ22m-L6UBYoSF2UOLMYB9uUgaKSN~z~OB0W8n6QX7L5e5re~SWOnNYZwlVk9TLJ9rbFGjOVnDGaBEIFP6JrNuqxqxemf27tffJFTOg0VJqrJs6q5n~P3dQaQ2JvlG~0ChvXRsSnmOwQqq7JC3cpL3jGsXTi-argEjlg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA" />
            <p>Foods</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default About