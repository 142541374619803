import React from 'react'
import './App.css'
import Navbar from './components/navbar/Navbar'
import About from './components/about/About'
import Footer from './components/footer/Footer'


const App = () => {
  return (
    <>
      <Navbar />
      <About />
      <Footer />
    </>
  )
}

export default App
