import React from 'react'
import './footer.css'

const Footer = () => {
  return (

    <footer>
      <div className="footer-top">
        <div className="footer-left">
          <img src="images/logo.png" />
        </div>
        <div className="footer-right">
          <div className="footer-right-box">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Testimonial</a></li>
              <li><a href="#">Gallery</a></li>
              <li><a href="#">Site Map</a></li>
            </ul>
          </div>
          <div className="footer-right-box">
            <h4>Flats in Mumbai</h4>
            <ul>
              <li><a href="#">1BHK Andheri West</a></li>
              <li><a href="#">2 BHK Andheri West</a></li>
              <li><a href="#">3 BHK Andheri West</a></li>
              <li><a href="#">1BHK Goregaon East</a></li>
              <li><a href="#">2BHK Goregaon East</a></li>
            </ul>
          </div>
          <div className="footer-right-box">
            <h4>Projects in Mumbai</h4>
            <ul>
              <li><a href="#">Ongoing</a></li>
              <li><a href="#">Upcoming</a></li>
              <li><a href="#">Completed</a></li>
              <li><a href="#">Hetali Blessings</a></li>
              <li><a href="#">Hetali Anuchhaya</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="logo logo4">
        <img src="images/logo2.png" />
      </div>

      <p>Copyright © 2022 Hetali Enterprises - All Rights Reserved.</p>


    </footer>
  )
}

export default Footer